import { EnhancedStore } from "@reduxjs/toolkit/dist/configureStore"
import { parseCookies } from "universal-cookie/lib/utils"
import { AppContext } from "next/app"
import { DaDataIpLocateResponseType, fetchIpLocate } from "@/api/dadataAPI"
import { RootStateType } from "@/store/store"
import {
  getExpireOneYear,
  getFormatResponseLocation,
  getIp,
  isValidPathname,
} from "@/utils/common"
import {
  HOST_NAME_COOKIE_KEY,
  LOCATION_DEFAULT,
  LOCATION_NAME_COOKIE_KEY,
  SITE_DOMAIN,
  VISIT_NAME_COOKIE_KEY,
} from "@/utils/constants"
import { getIsBot } from "@/utils/navigator"

const getPathnameSubdomain = (slug = "", pathname = "") =>
  `https://${slug}.${SITE_DOMAIN}${pathname}`

const redirectHandle = ({
  res,
  slug = "",
  pathname = "",
}: Pick<AppContext["ctx"], "res"> & {
  slug?: string
  pathname?: string
}) => {
  res?.writeHead(301, {
    Location: getPathnameSubdomain(slug, pathname),
  })
  res?.end()
}

const getServerSideProps =
  (reduxStore: EnhancedStore<RootStateType>) =>
  async (payload: Pick<AppContext["ctx"], "res" | "req">) => {
    const { res, req } = payload
    let madeCookies = res?.getHeader("Set-Cookie") || ""

    // текущий хост
    const hostname = req?.headers.host || ""

    madeCookies += `${HOST_NAME_COOKIE_KEY}=${encodeURIComponent(
      hostname,
    )}; Path=/; Expires=${getExpireOneYear().toUTCString()}`

    res?.setHeader("Set-Cookie", madeCookies)

    if (!req) {
      return {}
    }

    // Определяем, работаем ли на localhost или в продакшене
    const isLocalhost = hostname.includes("localhost")
    const pathname = req.url
    const parsedCookie = parseCookies(req.headers.cookie)

    const visitCookie = !!parsedCookie
      ? (parsedCookie[VISIT_NAME_COOKIE_KEY] as string | undefined) || ""
      : ""

    // Есть ли поддомен
    // Извлекаем поддомен (например, penza.groster.me -> penza)
    let subdomain = !!hostname ? hostname.split(".")[0] ?? null : null
    if (!!subdomain) {
      subdomain =
        subdomain !== "www" && !subdomain.startsWith("groster")
          ? subdomain
          : null
    }

    console.log("subdomain ", subdomain, " visitCookie ", visitCookie)

    // если есть значение города в cookie
    // и совпадает с поддоменом - ничего не делаем
    // если не совпадают, делаем редирект на поддомен из cookie
    if (!!subdomain && !!visitCookie) {
      if (subdomain === visitCookie) {
        return
      } else {
        if (isLocalhost) {
          return
        }
        redirectHandle({
          res,
          slug: visitCookie,
          pathname,
        })
        return
      }
    }

    // если нет записи города в cookie
    // определяем гео по ip
    if (!visitCookie) {
      console.log("pathname ", pathname)

      // существуют страницы, создающие фид или отображающие фид по урлу вместо файла
      // эти страницы могут парсить роботы и множество раз
      // нам не нужно чтобы dadata тратила на это запросы
      if (!isValidPathname(pathname)) {
        return
      }

      const userAgent = req.headers["user-agent"] || ""

      const youIsBot = getIsBot(userAgent)
      console.log("youIsBot ", youIsBot)

      if (youIsBot) {
        return
      }

      // moscow 217.29.49.0
      // penza 77.51.199.15
      // sntp 5.3.184.216
      // volgograd 5.139.0.197
      const ip = getIp(req, "5.139.0.197")
      console.log("getIp ip ", ip)
      let location = { ...LOCATION_DEFAULT, is_guessed: true }
      let visit: string | null = null

      if (!!ip) {
        const response = await Promise.all([
          fetchIpLocate({
            ip,
          }),
          fetchIpLocate({
            ip,
            language: "en",
          }),
        ]).catch((err) => console.log("error all promises ", err))

        const ipLocateResult: DaDataIpLocateResponseType | null = response[0]
        const ipLocateResultEn: DaDataIpLocateResponseType | null = response[1]

        if (!!ipLocateResult && !!ipLocateResult.location) {
          location = {
            ...getFormatResponseLocation(ipLocateResult.location.data),
            is_guessed: false,
          }
        }

        console.log("ipLocateResultEn ", ipLocateResultEn)
        if (!!ipLocateResultEn && !!ipLocateResultEn.location) {
          visit = (ipLocateResultEn.location.data?.city || "")
            .trim()
            .toLowerCase()
            .replaceAll(" ", "-")
        }
      }

      console.log("visit ", visit)

      madeCookies += `${LOCATION_NAME_COOKIE_KEY}=${encodeURIComponent(
        JSON.stringify(location),
      )}; Path=/; Expires=${getExpireOneYear().toUTCString()}; Domain=${
        isLocalhost
          ? ""
          : `.${SITE_DOMAIN}; SameSite=lax; Secure=${String(!isLocalhost)}`
      }`

      madeCookies += `${LOCATION_NAME_COOKIE_KEY}=${encodeURIComponent(
        JSON.stringify(visit),
      )}; Path=/; Expires=${getExpireOneYear().toUTCString()}; Domain=${
        isLocalhost
          ? ""
          : `.${SITE_DOMAIN}; SameSite=lax; Secure=${String(!isLocalhost)}`
      }`

      res?.setHeader("Set-Cookie", madeCookies)

      if (!!visit) {
        if (isLocalhost) {
          return
        }

        // Формируем URL поддомена
        redirectHandle({
          res,
          slug: visit,
          pathname,
        })
        return
      }
    } else {
      redirectHandle({
        res,
        slug: visitCookie,
        pathname,
      })
    }

    res?.setHeader("Set-Cookie", madeCookies)

    return
  }

export { getServerSideProps as locationServerProps }
