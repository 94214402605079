import { PictureListItemType } from "@/components/List/PictureList"
import { colors } from "@/styles/utils/vars"
import { LinkItemType, LocationType } from "@/types"

export const isDevelopment = process.env.NODE_ENV === "development"
export const TITLE_SITE_RU = "Гростер"
export const SITE_DOMAIN = isDevelopment
  ? "localhost:3000"
  : process.env.NEXT_PUBLIC_BASE_URL || "groster.me"
export const SITE_URL = `${
  isDevelopment ? "http://" : "https://"
}${SITE_DOMAIN}`
export const BASE_URL =
  process.env.NEXT_PUBLIC_API_BASE_URL || `https://api.groster.me`
export const BASE_VERSION_URL =
  process.env.NEXT_PUBLIC_API_BASE_VERSION || "/api/v1"

export const ROUTES = {
  home: "/",
  favorites: "/favorites",
  compares: "/compares",
  catalog: "/catalog",
  cart: "/cart",
  checkout: "/checkout",
  thank: "/thank",
  product: "/product",
  account: "/account",
  historyAccount: "/history",
  subscriptions: "/subscriptions",
  staff: "/staff",
  payers: "/payers",
  addresses: "/addresses",
  pricelist: "/pricelist",
  recommendations: "/recommendations",
  about: "/about",
  stores: "/stores",
  policy: "/policy",
  contacts: "/contacts",
  help: "/help",
  paymentTerm: "/term_payment",
  deliveryTerm: "/term_delivery",
  productWarranty: "/product_warranty",
  faq: "/faq",
  agree: "/agree",
  search: "/search",
  return: "/return",
  notFound: "/404",
  shared: "/shared",
  dashboard: "/dashboard",
  blog: "/blog",
}

export const CURRENCY = "₽"
export const CURRENCY_SHORT_NAME = "руб."

export const enum Currency {
  RUB = "RUB",
}

export const LOCATION_NAME_COOKIE_KEY = "location"
export const VISIT_NAME_COOKIE_KEY = "visit"
export const HOST_NAME_COOKIE_KEY = "host"
export const LOCATION_DEFAULT: LocationType = {
  city: "Волгоград",
  city_type: "г",
  region: "Волгоградская",
  region_type: "обл",
  region_kladr_id: "3400000100000",
  city_en: "volgograd",
}
export const ALLOWED_COOKIE_KEY = "isAllowedCookie"
export const SCROLL_POSITION_CATALOG_COOKIE_KEY = "scrollPositionCatalog"

export const LOCATIONS_IMPORTANT: LocationType[] = [
  {
    city: "Москва",
    city_type: "г",
    region: "Москва",
    region_type: "г",
    region_kladr_id: "7700000000000",
    city_en: "moscow",
  },
  {
    city: "Санкт-Петербург",
    city_type: "г",
    region: "Ленинградская",
    region_type: "обл",
    region_kladr_id: "7800000000000",
    city_en: "saint-petersburg",
  },
  {
    city: "Барнаул",
    city_type: "г",
    region: "Алтайский",
    region_type: "край",
    region_kladr_id: "2200000100000",
    city_en: "barnaul",
  },
  {
    city: "Владивосток",
    city_type: "г",
    region: "Приморский",
    region_type: "край",
    region_kladr_id: "2500000100000",
    city_en: "vladivostok",
  },
  {
    ...LOCATION_DEFAULT,
  },
  {
    city: "Воронеж",
    city_type: "г",
    region: "Воронежская",
    region_type: "обл",
    region_kladr_id: "3600000100000",
    city_en: "voronezh",
  },
  {
    city: "Екатеринбург",
    city_type: "г",
    region: "Свердловская",
    region_type: "обл",
    region_kladr_id: "6600000100000",
    city_en: "yekaterinburg",
  },
  {
    city: "Ижевск",
    city_type: "г",
    region: "Удмуртская",
    region_type: "Респ",
    region_kladr_id: "1800000100000",
    city_en: "izhevsk",
  },
  {
    city: "Иркутск",
    city_type: "г",
    region: "Иркутская",
    region_type: "обл",
    region_kladr_id: "3800000300000",
    city_en: "irkutsk",
  },
  {
    city: "Казань",
    city_type: "г",
    region: "Татарстан",
    region_type: "Респ",
    region_kladr_id: "1600000100000",
    city_en: "kazan",
  },
  {
    city: "Кемерово",
    city_type: "г",
    region: "Кемеровская область - Кузбасс",
    region_type: "обл",
    region_kladr_id: "4200000900000",
    city_en: "kemerovo",
  },
  {
    city: "Краснодар",
    city_type: "г",
    region: "Краснодарский",
    region_type: "край",
    region_kladr_id: "2300000100000",
    city_en: "krasnodar",
  },
  {
    city: "Красноярск",
    city_type: "г",
    region: "Красноярский",
    region_type: "край",
    region_kladr_id: "2400000100000",
    city_en: "krasnoyarsk",
  },
  {
    city: "Махачкала",
    city_type: "г",
    region: "Дагестан",
    region_type: "Респ",
    region_kladr_id: "0500000100000",
    city_en: "makhachkala",
  },
  {
    city: "Нижний Новгород",
    city_type: "г",
    region: "Нижегородская",
    region_type: "обл",
    region_kladr_id: "5200000100000",
    city_en: "nizhny-novgorod",
  },
  {
    city: "Новосибирск",
    city_type: "г",
    region: "Новосибирская",
    region_type: "обл",
    region_kladr_id: "5400000100000",
    city_en: "novosibirsk",
  },
  {
    city: "Омск",
    city_type: "г",
    region: "Омская",
    region_type: "обл",
    region_kladr_id: "5500000100000",
    city_en: "omsk",
  },
  {
    city: "Оренбург",
    city_type: "г",
    region: "Оренбургская",
    region_type: "обл",
    region_kladr_id: "5600000100000",
    city_en: "orenburg",
  },
  {
    city: "Пермь",
    city_type: "г",
    region: "Пермский",
    region_type: "край",
    region_kladr_id: "5900000100000",
    city_en: "perm",
  },
  {
    city: "Ростов-на-Дону",
    city_type: "г",
    region: "Ростовская",
    region_type: "обл",
    region_kladr_id: "6100000100000",
    city_en: "rostov-on-don",
  },
  {
    city: "Самара",
    city_type: "г",
    region: "Самарская",
    region_type: "обл",
    region_kladr_id: "6300000100000",
    city_en: "samara",
  },
  {
    city: "Саратов",
    city_type: "г",
    region: "Саратовская",
    region_type: "обл",
    region_kladr_id: "6400000100000",
    city_en: "saratov",
  },
  {
    city: "Тольятти",
    city_type: "г",
    region: "Самарская",
    region_type: "обл",
    region_kladr_id: "6300000700000",
    city_en: "tolyatti",
  },
  {
    city: "Томск",
    city_type: "г",
    region: "Томская",
    region_type: "обл",
    region_kladr_id: "7000000100000",
    city_en: "tomsk",
  },
  {
    city: "Тюмень",
    city_type: "г",
    region: "Тюменская",
    region_type: "обл",
    region_kladr_id: "7200000100000",
    city_en: "tyumen",
  },
  {
    city: "Уфа",
    city_type: "г",
    region: "Башкортостан",
    region_type: "Респ",
    region_kladr_id: "0200000100000",
    city_en: "ufa",
  },
  {
    city: "Хабаровск",
    city_type: "г",
    region: "Хабаровский",
    region_type: "край",
    region_kladr_id: "2700000100000",
    city_en: "khabarovsk",
  },
  {
    city: "Челябинск",
    city_type: "г",
    region: "Челябинская",
    region_type: "обл",
    region_kladr_id: "7400000100000",
    city_en: "chelyabinsk",
  },
  {
    city: "Ярославль",
    city_type: "г",
    region: "Ярославская",
    region_type: "обл",
    region_kladr_id: "7600000100000",
    city_en: "yaroslavl",
  },
]

export const WEEKDAYS_SHORT = {
  ru: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
}
export const MONTHS = {
  ru: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
}

export const MONTHS_DECLINATION = {
  ru: [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ],
}

export const WEEKDAYS_LONG = {
  ru: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
}

export const LIMIT_PUSH_COUNTER_DEFAULT = 9

export const TIMEOUT_SUCCESS = 4000

export const LIST_PICTURE_ITEMS: PictureListItemType[] = [
  {
    title: "Широкий",
    description:
      "Гростер — это широко. Широкий ассортимент, размах и цели. Мы двигаемся широко, покрывая новые территории и сферы товаров. Широкий сервис: значит каждый клиент встречается тепло и с любовью.",
    icon: "Transaction",
  },
  {
    title: "Приятный",
    description:
      "Приятная надежность. Мы стараемся удовлетворять все потребности наших клиентов и сотрудников. Мы хотим быть той компанией с которой и в которой приятно работать.",
    icon: "Heart",
  },
  {
    title: "Преимущественный",
    description:
      "В сравнении с конкурентами Гростер всегда старается выиграть. Дать на один плюс больше. Выделиться из толпы, предоставляя больше плюсов и преимуществ для клиентов и сотрудников.",
    icon: "Trophy",
  },
]

export const enum CONTACTS_ALIAS {
  VIBER = "viber",
  CHAT = "chat",
  PHONE = "phone",
  WHATSAPP = "whatsapp",
  TELEGRAM = "telegram",
}

export const CONTACTS: Record<
  CONTACTS_ALIAS | string,
  Omit<LinkItemType, "title"> & { fillIcon?: string; title: string }
> = {
  [CONTACTS_ALIAS.PHONE]: {
    icon: "Phone",
    title: "8 (844) 220-36-35",
    path: "tel:88442203635",
    fillIcon: colors.brand.purple,
  },
  [CONTACTS_ALIAS.VIBER]: {
    icon: "Viber",
    title: "Viber",
    path: "viber://chat?number=+79053303635",
    fillIcon: colors.brand.purpleDarken,
  },
  [CONTACTS_ALIAS.WHATSAPP]: {
    icon: "WhatsApp",
    title: "WhatsApp",
    path: "https://wa.me/+79053303635/",
    fillIcon: colors.green,
  },
  [CONTACTS_ALIAS.TELEGRAM]: {
    icon: "Telegram",
    title: "Telegram",
    path: "tg://resolve/?domain=Groster_bot",
    fillIcon: colors.social.tg,
  },
  [CONTACTS_ALIAS.CHAT]: {
    icon: "Chat",
    title: "+7 905 330-36-35",
    path: "tel:+79053303635",
  },
}

export const CONTACTS_ITEMS = Object.values(CONTACTS)

export const DELIVERY_METHODS = [
  "Самовывоз из магазина-партнёра;",
  "Курьером;",
  "Транспортной компанией.",
]

export const DELIVERY_TERMS = [
  "По Волгограду и в город Волжский – в день заказа;",
  "В города Волгоградской области и другие близлежащие города – обговаривается с менеджером, срок не превышает неделю.",
  "В другие города РФ – в соответствии со сроками транспортной компании, до транспортной компании – в тот же день.",
  "Если у Вас срочный заказ – мы отправим его на такси.",
]

export const CREATE_ORDER_STEPS = [
  "Выбирайте все понравившиеся позиции на сайте;",
  "Оформляете заказ;",
  "Ждите доставку, или пока с Вами свяжется менеджер, если Вы это просили в комментарии к заказу.",
]

export const RETURN_PRODUCT_STEPS = [
  "номер заказа;",
  "наименование товара;",
  "количество единиц;",
  "характер брака;",
  "приложить фото, где отчётливо виден брак.",
]
export const VIEW_PRODUCTS_GRID = "grid"
export const VIEW_PRODUCTS_LIST = "list"
export const VIEW_PRODUCTS_CHECKOUT = "checkout"
export const MODIFIER_PRODUCTS_MINI = "mini"
export const MODIFIER_PRODUCTS_COMPARES = "compares"

export const DATE_STORE = {
  iso: null as string | null,
}

export const NO_IMAGE_PNG = `/images/noimage.png`

export const FOUNDED_DECLINATIONS = ["Найден", "Найдено", "Найдено"]
export const PRODUCTS_DECLINATIONS = ["товар", "товара", "товаров"]
export const STORES_DECLINATIONS = ["складе", "складах", "складах"]
export const DAYS_DECLINATIONS = ["день", "дня", "дней"]
export const HOURS_DECLINATIONS = ["час", "часа", "часов"]
export const MINUTES_DECLINATIONS = ["минута", "минуты", "минут"]
export const SECONDS_DECLINATIONS = ["секунда", "секунды", "секунд"]

export const PICKUP_SHIPPING_METHOD_ALIAS = "pickup"

export const IMAGE_TITLE_CARD_SIZE_MODIFICATOR = 30
export const IMAGE_PRODUCT_CARD_SIZE_MODIFICATOR = 30

export const enum SubscribeType {
  MEMO = 1, // памятка
  INSTRUCTION = 2, // инструкция
}

export const EMPTY_DATA_PLACEHOLDER = "Без названия"
export const MESSAGE_ERROR_DEFAULT = "Произошла ошибка. Попробуйте еще раз"
export const TITLES = {
  BEST_DEALS: "Лучшие предложения для бизнеса",
  KIT_CONTENTS: "Состав комплекта",
}

export const enum GTAG_EVENTS {
  CLICK_LOGIN = "webit_click_login",
  LOGIN_COMPLETE = "webit_login_complete",
  ADD_TO_CART = "webit_add_to_cart",
  ADD_TO_CART_SAMPLE = "webit_add_to_cart_sample",
  ADD_TO_FAVORITES = "webit_add_to_favourites",
  CLICK_ORDER = "webit_widget_order",
  ORDER_DONE = "webit_widget_order_done",
  CART_ORDER_DONE = "webit_cart_order_done",
  CALLBACK_FORM = "webit_callback_form",
  CONTACTS_FORM = "webit_contacts_form",
  FEEDBACK_FORM = "webit_callback_form_contacts",
  MESSAGE_COMMERS = "webit_message_commers",
  PRODUCT_MESSAGE = "webit_product_message",
  MAIL_MESSAGE = "webit_mail_message",
  REPEAT_MESSAGE = "webit_repit_message",
  PAGE_404 = "webit_404_error",
}

export const enum FeedbackFormType {
  RECALL = 1,
  VIDEO_PRESENTATION = 2,
}

export const enum THEME_MODE {
  DARK = "dark",
  LIGHT = "light",
}

export const FAVICON = {
  "256": "/icon-256x256.png",
}

export const reCaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ""

export const NBSP_SYMBOL = new RegExp(String.fromCharCode(160), "g")

export const enum PLATFORM {
  IOS = "ios",
  ANDROID = "android",
  UNKNOWN = "unknown",
}

export const MIN_COST_ORDER_RUB = 1500
